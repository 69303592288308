<template>
  <div class="periphery-wrap">
    <h3 class="w-title-gradients">项目周边人群画像对比
      <el-tooltip
        class="item"
        popper-class="planTooltip"
        effect="dark"
        placement="bottom"
      >
        <span>
          <i class="icon-help-tips"></i>
        </span>
        <div slot="content">显示项目周边半径1-3公里范围内的可监测人群信息</div>
      </el-tooltip>
    </h3>
    <div class="select-wrap">
      <div class="w-select-screen-border">
        <div class="radio-wrap">
          <el-radio
            v-model="jobOrPerType"
            label="1"
            @change="chageRadio"
          >工作人口</el-radio>
          <el-radio
            v-model="jobOrPerType"
            label="2"
            @change="chageRadio"
          >常住人口</el-radio>
        </div>
        <el-select
          popper-class="w-block-select-down w-block-select-down-border"
          v-model="radiusType"
          @change="changeType"
          placeholder="请选择"
          size='medium'
          class="select-extra"
        >
          <el-option
            v-for="item in typeList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="population-wrap">
      <div class="item-wrap">
        <div
          class="item"
          v-for="item in brandCompartList"
          :key="item.projectId"
        >
          <div class="population-number">
            <div class="value">{{item.population}}人</div>
            <div class="label">周边{{jobOrPerType === '1' ? '工作' : '常住'}}人口</div>
          </div>
        </div>
      </div>
      <div class="sex-charts">
        <div class="brand-tips"> <span></span> 性别对比</div>
        <div
          v-for="index in (comProjectIds.length + 1)"
          :key="index"
          class="chart-item"
        >
          <div
            class="charts-bar"
            style="height: 200px;"
            :id="'sexBar' + (index)"
          ></div>
        </div>
      </div>
      <div class="sex-charts">
        <div class="brand-tips"> <span></span> 年龄对比</div>
        <div
          v-for="index1 in (comProjectIds.length + 1)"
          :key="index1"
          class="chart-item"
        >
          <div
            class="charts-bar"
            style="height: 280px;"
            :id="'ageBar' + (index1)"
          ></div>
        </div>
      </div>
      <div class="progress-wrap">
        <div class="brand-tips"> <span></span> 行业对比</div>
        <div
          class="progress-item"
          v-for="(item, index2) in brandCompartList"
          :key="index2"
        >
          <div class="scroll-y">
            <div
              class="content-item"
              v-for="(item1, idx) in item.business"
              :key="idx"
            >
              <div class="info">
                <div class="text">{{item1.lable}}</div>
                <div class="value">{{item1.percent}}%</div>
              </div>
              <div class="progress">
                <div
                  :style="{'width': item1.percent + '%'}"
                  class="value"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import echarts from 'echarts'
export default {
  data () {
    return {
      brandCompartList: [],
      jobOrPerType: '1',
      typeList: [{ label: '周边半径1公里', value: 1 }, { label: '周边半径2公里', value: 2 }, { label: '周边半径3公里', value: 3 }],
      radiusType: 1,
      colors: ['#FFB7F5', '#7F6DFF', '#90DCF2', '#3E8CFF', '#FFCE73', '#FFB7F5', '#7F6DFF', '#90DCF2', '#3E8CFF']
    }
  },
  props: {
    brandId: {
      type: Number
    },
    filtroProject: {
      type: Number
    },
    filtroStore: {
      type: String
    },
    comProjectIds: {
      type: Array
    }
  },
  mounted () {
    this.init()
    // this.$nextTick(() => {
    //   for (let i = 1; i < 4; i++) {
    //     this.barChartsFunc('sexBar' + i)
    //     this.barChartsFunc('ageBar' + i)
    //   }
    // })
  },
  methods: {
    init () {
      const data = {
        brandId: this.brandId,
        projectId: this.filtroProject,
        comProjectIds: this.comProjectIds,
        storeCode: this.filtroStore,
        radiusType: this.radiusType,
        jobOrPerType: this.jobOrPerType
      }
      this.axios.post(api.portrait, data)
        .then((res) => {
          this.$nextTick(() => {
            this.brandCompartList = res.data.data
            for (let i = 0; i < res.data.data.length; i++) {
              this.barChartsFunc('sexBar' + (i + 1), res.data.data[i])
              this.barChartsFunc2('ageBar' + (i + 1), res.data.data[i])
            }
          })
        })
    },
    chageRadio () {
      this.init()
    },
    changeType () {
      this.init()
    },
    barChartsFunc (dom, data) {
      const params = []
      for (let i = 0; i < data.sex.labels.length; i++) {
        const obj = {}
        obj.value = data.sex.values[i]
        obj.name = data.sex.labels[i]
        params.push(obj)
      }
      const myChart = echarts.init(document.getElementById(dom))
      const option = {
        tooltip: {
          trigger: 'item',
          formatter: '<span style="color: #808191;">{b}:</span>\n{d}% ',
          backgroundColor: 'rgba(0,0,0,0.85)',
          padding: 10
        },
        legend: {
          bottom: '-2%',
          left: 'center',
          show: true,
          textStyle: {
            color: '#808191'
          },
          itemWidth: 10,
          itemHeight: 10,
          icon: 'circle'
        },
        color: this.colors,
        series: [
          {
            name: '',
            type: 'pie',
            radius: ['50%', '70%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: false,
                fontSize: '40',
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: params
          }
        ]
      }
      myChart.setOption(option)
    },
    barChartsFunc2 (dom, data) {
      // console.log(data)
      const params = []
      for (let i = 0; i < data.age.labels.length; i++) {
        const obj = {}
        obj.value = data.age.values[i]
        obj.name = data.age.labels[i]
        params.push(obj)
      }
      const myChart = echarts.init(document.getElementById(dom))
      const option = {
        tooltip: {
          trigger: 'item',
          formatter: '<span style="color: #808191;">{b}岁:</span>\n{d}% ',
          backgroundColor: 'rgba(0,0,0,0.85)',
          padding: 10
        },
        legend: {
          bottom: '-2%',
          left: 'center',
          show: true,
          textStyle: {
            color: '#808191'
          },
          itemWidth: 10,
          itemHeight: 10,
          icon: 'circle',
          formatter: (params) => {
            return `${params}岁`
          }
        },
        color: this.colors,
        series: [
          {
            name: '',
            type: 'pie',
            radius: ['50%', '70%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: false,
                fontSize: '40',
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: params
          }
        ]
      }
      myChart.setOption(option)
    }
  }
}
</script>

<style lang="stylus" scoped>
.select-extra>>>
  margin-right 18px
  background #373A43
  border-radius 2px
  .el-input__inner
    box-sizing border-box
    width 160px
    height 34px
    border 1px solid #373A43
    color #808191
  .el-input.is-focus .el-input__inner
    border 1px solid #373A43 !important
  .el-input__inner:hover
    border 1px solid #373A43 !important
.periphery-wrap
  background-color #272930
  margin-top 14px
  padding-bottom 40px
  .radio-wrap
    display inline-block
    margin-left auto
    margin-right 50px
  .select-wrap
    position relative
    display flex
    .w-select-screen-border
      margin-left auto
  .population-wrap
    margin-top 50px
    .item-wrap
      display flex
      .item
        flex 1
        text-align center
        .value
          font-size 28px
          font-weight bold
        .label
          color #808191
          font-size 14px
    .sex-charts
      display flex
      position relative
      border 1px solid #373A43
      margin 40px 16px
      padding-bottom 40px
      .chart-item
        flex 1
        // text-align center
        .charts-bar
          width 200px
          height 270px
          margin auto
        .colors
          display flex
          justify-content center
          .colors-item
            .name
              font-size 12px
              color #808191
              font-weight 400
              span
                display inline-block
                width 8px
                height 8px
                border-radius 50%
                vertical-align initial
                margin-left 22px
                margin-right 5px
            .value
              margin-left 30px
              font-size 14px
              color #fff
    .progress-wrap
      display flex
      position relative
      border 1px solid #373A43
      margin 40px 16px
      padding-bottom 40px
      justify-content center
      padding-top 40px
      .progress-item
        flex 1
        text-align center
        margin-top 50px
        .scroll-y
          width 400px
          height 470px
          margin auto
          overflow-y auto
          .content-item
            padding 0 40px
            margin-bottom 30px
            .info
              display flex
              .text
                margin-right auto
                font-size 14px
                color #B2B3BD
              .value
                margin-left auto
                color #fff
                font-size 16px
            .progress
              position relative
              height 10px
              width 100%
              background-color #373A43
              border-radius 5px
              margin-top 7px
              .value
                position absolute
                left 0
                top 0
                width 70%
                height 10px
                background-color #3E8CFF
                border-radius 5px
.brand-tips
  position absolute
  left 20px
  top 40px
  font-size 16px
  font-weight 800
  span
    position absolute
    left -6px
    top 4px
    display inline-block
    width 2px
    height 16px
    background-color #F89407
</style>
