<template>
  <div class="portrait-wrap">
    <h3 class="w-title-gradients">项目周边同业态竞争对比
      <el-tooltip
        class="item"
        popper-class="planTooltip"
        effect="dark"
        placement="bottom"
      >
        <span>
          <i class="icon-help-tips"></i>
        </span>
        <div slot="content">显示项目周边半径1-3公里范围内当前品牌对应业态的铺位数量</div>
      </el-tooltip>
    </h3>
    <div class="select-wrap">
      <!-- <div class="brand-tips"> <span></span> 业态对比</div> -->
      <div class="w-select-screen-border">
        <el-select
          popper-class="w-block-select-down w-block-select-down-border"
          v-model="radiusType"
          @change="changeType"
          placeholder="请选择"
          size='medium'
          class="select-extra"
          style="width: 150px;"
        >
          <el-option
            v-for="item in typeList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="brand-wrap">
      <div class="item">
        <div class="store-wrap-box">
          <div
            class="store-wrap"
            v-for="item in brandCompartList"
            :key="item.operating"
          >
            <div class="number">{{item.berthNum}}</div>
            <div class="label">铺位数</div>
          </div>
        </div>
        <div class="charts-wrap">
          <div
            class="charts"
            v-for="index in (comProjectIds.length + 1)"
            :key="index"
          >
            <div :id="'portraitBar' + (index)"></div>
          </div>
        </div>

        <!-- <div class="industry-colors">
          <div
            class="colors-item"
            v-for="item in brandCompartList"
            :key="item.operating"
          >
            <div
              class="type-wrap"
              v-for="(list, idx) in item.percentage.labels"
              :key="list"
            >
              <div class="name"><span :style="{'background': colors[idx]}"></span>{{list}}</div>
              <div class="value">70.33%</div>
            </div>

          </div>
          <div class="colors-item">
            <div class="name"><span :style="{'background': colors[1]}"></span>文体娱乐</div>
            <div class="value">70.33%</div>
          </div>
          <div class="colors-item">
            <div class="name"><span :style="{'background': colors[2]}"></span>文体娱乐</div>
            <div class="value">70.33%</div>
          </div>
          <div class="colors-item">
            <div class="name"><span :style="{'background': colors[3]}"></span>文体娱乐</div>
            <div class="value">70.33%</div>
          </div>
          <div class="colors-item">
            <div class="name"><span :style="{'background': colors[4]}"></span>文体娱乐</div>
            <div class="value">70.33%</div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import echarts from 'echarts'
export default {
  data () {
    return {
      radiusType: 1,
      brandCompartList: [],
      typeList: [{ label: '周边半径1公里', value: 1 }, { label: '周边半径2公里', value: 2 }, { label: '周边半径3公里', value: 3 }],
      colors: ['#FFB7F5', '#7F6DFF', '#90DCF2', '#3E8CFF', '#FFCE73', '#ff0000', '#7F6DFF', '#90DCF2', '#3E8CFF']
    }
  },
  props: {
    brandId: {
      type: Number
    },
    filtroProject: {
      type: Number
    },
    filtroStore: {
      type: String
    },
    comProjectIds: {
      type: Array
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      const data = {
        brandId: this.brandId,
        projectId: this.filtroProject,
        comProjectIds: this.comProjectIds,
        storeCode: this.filtroStore,
        radiusType: this.radiusType
      }
      this.axios.post(api.sameFormatComparing, data)
        .then((res) => {
          this.$nextTick(() => {
            this.brandCompartList = res.data.data
            for (let i = 0; i < res.data.data.length; i++) {
              this.barChartsFunc('portraitBar' + (i + 1), res.data.data[i])
            }
          })
        })
    },
    changeType () {
      this.init()
    },
    barChartsFunc (dom, data) {
      // console.log(data)
      // const params = []
      // for (let i = 0; i < 2; i++) {
      //   const obj = {}
      //   obj.value = data[i].shopPercentage
      //   obj.name = data[i].
      // }
      const myChart = echarts.init(document.getElementById(dom))
      const option = {
        tooltip: {
          trigger: 'item',
          formatter: '<span style="color: #808191;">{b}:</span>\n{d}% ',
          backgroundColor: 'rgba(0,0,0,0.85)',
          padding: 10
        },
        legend: {
          bottom: '-2%',
          left: 'center',
          show: true,
          textStyle: {
            color: '#808191'
          },
          itemWidth: 10,
          itemHeight: 10,
          icon: 'circle'
        },
        color: this.colors,
        series: [
          {
            name: '',
            type: 'pie',
            radius: ['50%', '70%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: false,
                fontSize: '40',
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: [
              { value: data.streetNum, name: '街铺数' },
              { value: data.shopNum, name: '购物中心店' }
            ]
          }
        ]
      }
      myChart.setOption(option)
    }
  }
}
</script>

<style lang="stylus" scoped>
.select-extra>>>
  margin-right 18px
  background #373A43
  border-radius 2px
  .el-input__inner
    box-sizing border-box
    width 150px
    height 34px
    border 1px solid #373A43
    color #808191
  .el-input.is-focus .el-input__inner
    border 1px solid #373A43 !important
  .el-input__inner:hover
    border 1px solid #373A43 !important
.portrait-wrap
  background-color #272930
  margin-top 14px
  padding-bottom 60px
  .select-wrap
    position relative
    display flex
    .brand-tips
      position absolute
      left 20px
      top 160px
      font-size 16px
      font-weight 800
      span
        position absolute
        left -6px
        top 4px
        display inline-block
        width 2px
        height 16px
        background-color #F89407
    .w-select-screen-border
      margin-left auto
  .brand-wrap
    // display flex
    margin-top 50px
    .item
      // flex 1
      .store-wrap-box
        display flex
        .store-wrap
          flex 1
          text-align center
      .charts-wrap
        display flex
        .charts
          flex 1
          #portraitBar1, #portraitBar2, #portraitBar3
            width 200px
            height 200px
            margin auto
      .industry-colors
        display flex
        justify-content center
        .colors-item
          .name
            font-size 12px
            color #808191
            font-weight 400
            span
              display inline-block
              width 8px
              height 8px
              border-radius 50%
              vertical-align initial
              margin-left 22px
              margin-right 5px
          .value
            margin-left 30px
            font-size 14px
            color #fff
  .store-wrap
    .number
      color #fff
      font-size 28px
      font-weight bold
    .label
      color #808191
      font-size 14px
      margin-top 5px
</style>
