<template>
  <div class="industry-wrap">
    <h3 class="w-title-gradients">同楼层/业态品牌对比</h3>
    <div class="select-wrap">
      <div class="brand-tips"> <span></span> 品牌对比 </div>
      <div class="w-select-screen-border">
        <el-select
          popper-class="w-block-select-down w-block-select-down-border"
          v-model="lcOrYtType"
          @change="changeType"
          placeholder="请选择"
          size='medium'
          class="select-extra"
        >
          <el-option
            v-for="item in typeList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="brand-wrap">
      <div class="item">
        <div class="charts-wrap">
          <div
            class="charts"
            v-for="index in (comProjectIds.length + 1)"
            :key="index"
          >
            <div :id="'bar' + (index)"></div>
          </div>
        </div>
        <div class="industry-colors">
          <div
            class="colors-item"
            v-for="item in brandCompartList"
            :key="item.operating"
          >
            <!-- <div
              class="type-wrap"
              v-for="(list, idx) in item.percentage.labels"
              :key="list"
            >
              <div class="name"><span :style="{'background': colors[idx]}"></span>{{list}}</div>
            </div> -->
          </div>
          <!-- <div class="colors-item">
            <div class="name"><span :style="{'background': colors[1]}"></span>文体娱乐{{item}}</div>
            <div class="value">70.33%</div>
          </div>
          <div class="colors-item">
            <div class="name"><span :style="{'background': colors[2]}"></span>文体娱乐{{item}}</div>
            <div class="value">70.33%</div>
          </div>
          <div class="colors-item">
            <div class="name"><span :style="{'background': colors[3]}"></span>文体娱乐{{item}}</div>
            <div class="value">70.33%</div>
          </div>
          <div class="colors-item">
            <div class="name"><span :style="{'background': colors[4]}"></span>文体娱乐{{item}}</div>
            <div class="value">70.33%</div>
          </div> -->
        </div>
        <div class="index-table-wrap">
          <div
            class="index-table"
            v-for="item in brandCompartList"
            :key="item.operating"
          >
            <div class="table">
              <div class="table-item">
                <span
                  class="fixed"
                  v-if="item.hotType"
                >人气更高</span>
                <div class="value">{{item.hot}}</div>
                <div class="label">平均人气指数</div>
              </div>
              <div class="table-item">
                <span
                  class="fixed"
                  v-if="item.operatingType"
                >贡献更大</span>
                <div class="value">{{item.operating}}</div>
                <div class="label">平均租金贡献指数</div>
              </div>
              <div class="table-item">
                <span
                  class="fixed"
                  v-if="item.comprehensiveType"
                >实力更强</span>
                <div class="value">{{item.comprehensive}}</div>
                <div class="label">平均综合实力指数</div>
              </div>
            </div>
          </div>
        </div>
        <div class="industry-list-wrap">
          <div
            class="industry-list scroll-y"
            v-for="(item, index) in brandCompartList"
            :key="index"
          >
            <div class="list-wrap">
              <div
                class="item"
                v-for="(list, list1) in item.brandList"
                :key="list1"
              >
                <div class="name">{{list.ytOrlcName}}</div>
                <div class="brand-name">
                  <span
                    v-for="name in list.brandList"
                    :key="name.brandId"
                    :class="[brandId === (name.brandId-0) ? 'active' : '']"
                    @click="toDetail(name)"
                  > {{name.brandName}} </span>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import echarts from 'echarts'
export default {
  data () {
    return {
      lcOrYtType: 0,
      brandCompartList: [],
      typeList: [{ label: '同楼层', value: 0 }, { label: '同业态', value: 1 }],
      colors: ['#FFB7F5', '#7F6DFF', '#90DCF2', '#3E8CFF', '#FFCE73', '#7fba7a', '#7F6DFF', '#90DCF2', '#3E8CFF'],
      barNumberList0: {},
      barNumberList1: {},
      barNumberList2: {}
    }
  },
  props: {
    brandId: {
      type: Number
    },
    filtroProject: {
      type: Number
    },
    filtroStore: {
      type: String
    },
    comProjectIds: {
      type: Array
    }
  },
  mounted () {
    this.init()
    this.$nextTick(() => {
      // for (let i = 1; i < 4; i++) {
      //   this.barChartsFunc('bar' + i)
      // }
      // this.barChartsFunc('bar1')
    })
  },
  methods: {
    init () {
      const data = {
        brandId: this.brandId,
        projectId: this.filtroProject,
        comProjectIds: this.comProjectIds,
        storeCode: this.filtroStore,
        lcOrYtType: this.lcOrYtType
      }
      this.axios.post(api.brandcompart, data)
        .then((res) => {
          this.$nextTick(() => {
            this.brandCompartList = res.data.data
            for (let i = 0; i < res.data.data.length; i++) {
              this.barChartsFunc('bar' + (i + 1), res.data.data[i])
            }
          })
        })
    },
    changeType () {
      this.init()
    },
    barChartsFunc (dom, data) {
      const params = []
      for (let i = 0; i < data.percentage.labels.length; i++) {
        const obj = {}
        obj.value = data.percentage.values[i]
        obj.name = data.percentage.labels[i]
        params.push(obj)
      }
      // console.log(params)
      const myChart = echarts.init(document.getElementById(dom))
      const option = {
        tooltip: {
          trigger: 'item',
          formatter: '<span style="color: #808191;">{b}:</span>\n{d}% ',
          backgroundColor: 'rgba(0,0,0,0.85)',
          padding: 10
        },
        legend: {
          bottom: '-2%',
          left: 'center',
          show: true,
          textStyle: {
            color: '#808191'
          },
          itemWidth: 10,
          itemHeight: 10,
          icon: 'circle'
        },
        color: this.colors,
        series: [
          {
            // name: '访问来源',
            type: 'pie',
            radius: ['40%', '60%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
              // normal: {
              //   formatter: '{d} %'
              // }
            },
            emphasis: {
              label: {
                show: false,
                fontSize: '40',
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: params
          }
        ]
      }
      myChart.setOption(option)
    },
    toDetail (item) {
      if (item.state === -6) {

      } else {
        const brandDetailsHref = this.$router.resolve({
          path: '/brandDetail',
          query: {
            id: item.brandId,
            source: 1,
            gbid: item.gbid,
            questionState: 0
          }
        })
        window.open('/' + brandDetailsHref.href, '_blank')
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.select-extra>>>
  margin-right 18px
  background #373A43
  .el-input__inner
    box-sizing border-box
    width 110px
    height 34px
    border 1px solid #373A43
    color #808191
  .el-input.is-focus .el-input__inner
    border 1px solid #373A43 !important
  .el-input__inner:hover
    border 1px solid #373A43 !important
.industry-wrap
  background-color #272930
  margin-top 14px
  padding-bottom 60px
  .select-wrap
    position relative
    display flex
    .brand-tips
      position absolute
      left 20px
      top 40px
      font-size 16px
      font-weight 800
      span
        position absolute
        left -6px
        top 4px
        display inline-block
        width 2px
        height 16px
        background-color #F89407
    .w-select-screen-border
      margin-left auto
  .brand-wrap
    // display flex
    .item
      // flex 1
      .charts-wrap
        display flex
        .charts
          flex 1
          #bar1, #bar2, #bar3
            min-width 400px
            height 300px
            margin auto
      .industry-colors
        display flex
        justify-content center
        .colors-item
          flex 1
          text-align center
          display flex
          .type-wrap
            .name
              font-size 12px
              color #808191
              font-weight 400
              span
                display inline-block
                width 8px
                height 8px
                border-radius 50%
                vertical-align initial
                margin-left 22px
                margin-right 5px
          .value
            margin-left 30px
            font-size 14px
            color #fff
      .index-table-wrap
        display flex
        .index-table
          flex 1
          margin-top 44px
          .table
            max-width 430px
            border 1px solid #373A43
            height 116px
            display flex
            justify-content center
            align-items center
            margin auto
            .table-item
              height 116px
              width 33%
              border-right 1px solid #373A43
              text-align center
              position relative
              &:last-child
                border none
              .value
                margin-top 27px
                font-size 36px
                font-weight bold
                color #fff
              .label
                font-size 14px
                font-weight 400
                color #808191
              .fixed
                position absolute
                right 0%
                top 0px
                width 64px
                height 31px
                box-shadow 0px 4px 16px rgba(0, 0, 0, 0.6)
                background-color rgba(0, 0, 0, 0.85)
                color #FF754C
                font-size 12px
                line-height 31px
                text-align center
      .industry-list-wrap
        display flex
        // border 4px solid #FFA2C0
        margin auto
        margin-top 35px
        max-width 1600px
        padding 15px 0
        .industry-list
          flex 1
          height 370px
          overflow-y auto
          max-width 430px
          margin auto
          .list-wrap
            padding 0 20px
            .item
              max-width 430px
              margin auto
              margin-top 44px
              .name
                text-align left
                font-size 16px
                font-weight bold
                color #fff
              .brand-name
                text-align left
                span
                  color rgba(128, 129, 145, 1)
                  font-size 14px
                  margin-right 5px
                  line-height 22px
                  cursor pointer
                  word-wrap break-word
                  // i.dised
                  // color #e5e5e5
                  // opacity 0.6
                  &:hover
                    color #fff
                    border-bottom 1px solid #fff
                  &.active
                    color #ff0000 !important
</style>
