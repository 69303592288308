<template>
  <div class="comparison-content-wrap">
    <div class="comparison-list">
      <div
        class="item"
        v-for="item in compContProjectList"
        :key="item.projectId"
      >
        <div class="project-img">
          <img
            :src="item.projectLogo ? item.projectLogo + '?w=222&h=166' : 'https://jscss.winshangdata.com/3.0/list/images/no-logo.png'"
            alt=""
          >
        </div>
        <div class="project-name">{{item.projectName}}</div>
      </div>
    </div>
    <div class="drop-wrap">
      <h3 class="w-title-gradients">落位信息</h3>
      <div class="item-wrap">
        <div
          class="item"
          v-for="item in seatingList"
          :key="item.projectId"
        >
          <div class="info">
            <div class="value">{{item.floor}}</div>
            <div class="label">落位楼层</div>
          </div>
          <div class="info">
            <div class="value">{{item.berthNo}}</div>
            <div class="label">落位铺位</div>
          </div>
          <div class="info">
            <div class="value"><span
                class="fixed"
                v-if="item.openDurationType"
              >留存更久</span>{{item.openDuration}}</div>
            <div class="label">留存时间</div>
          </div>
        </div>
      </div>
    </div>
    <div class="project-info">
      <h3 class="w-title-gradients">项目信息</h3>
      <div class="item-wrap">
        <div
          class="item"
          v-for="item in projectInfoList"
          :key="item.romoveRepeatFlow"
        >
          <div class="info">
            <div class="value">{{item.projectTypeName}}</div>
            <div class="label">项目类型</div>
          </div>
          <div class="info">
            <div class="value">{{item.projectGradeName ? item.projectGradeName : '-'}}<span
                class="fixed"
                v-if="item.projectGradeType"
              >档次更高</span></div>
            <div class="label">项目档次</div>
          </div>
          <div class="info">
            <div class="value">开业{{item.kaiyeDuration ? item.kaiyeDuration + '年' : '-'}}<span
                class="fixed"
                v-if="item.kaiyeDurationType"
              >开业更久</span></div>
            <div class="label">开业时间</div>
          </div>
          <div class="info">
            <div
              class="value"
              v-if="item.minFloor && item.maxFloor"
            >{{item.minFloor}}到{{item.maxFloor}}层</div>
            <div
              class="value"
              v-else
            >-</div>
            <div class="label">商业楼层</div>
          </div>
          <div class="info">
            <div class="value">{{item.shangyeArea}}㎡<span
                class="fixed"
                v-if="item.shangyeAreaType"
              >面积更大</span></div>
            <div class="label">商业面积</div>
          </div>
          <div class="info">
            <div class="value">{{item.belongDistrict ? item.belongDistrict : '-'}}</div>
            <div class="label">所属商圈</div>
          </div>
          <div class="info">
            <div class="value">{{item.romoveRepeatFlow ? item.romoveRepeatFlow + '人' : '-'}}<span
                class="fixed"
                v-if="item.romoveRepeatFlowType"
              >客流更旺</span></div>
            <div class="label">平日去重客流</div>
          </div>
          <div class="info">
            <div class="value">{{item.romoveRepeatFlowByHolidays ? item.romoveRepeatFlowByHolidays + '人' : '-'}}<span
                class="fixed"
                v-if="item.romoveRepeatFlowByHolidaysType"
              >客流更旺</span></div>
            <div class="label">节假日去重客流</div>
          </div>
        </div>
      </div>
    </div>
    <div class="insustry-wrap">
      <industry-comparison
        v-if="comProjectIds && comProjectIds.length > 0"
        :filtroProject="filtroProject"
        :brandId="brandId"
        :filtroStore="filtroStore"
        :comProjectIds="comProjectIds"
      ></industry-comparison>
    </div>
    <div class="portrait-wrap">
      <portrait-comparison
        v-if="comProjectIds && comProjectIds.length > 0"
        :filtroProject="filtroProject"
        :brandId="brandId"
        :filtroStore="filtroStore"
        :comProjectIds="comProjectIds"
      ></portrait-comparison>
    </div>
    <div class="periphery-wrap">
      <periphery-comparison
        v-if="comProjectIds && comProjectIds.length > 0"
        :filtroProject="filtroProject"
        :brandId="brandId"
        :filtroStore="filtroStore"
        :comProjectIds="comProjectIds"
      ></periphery-comparison>
    </div>
    <comparison-tab
      v-if="tabProjectList && tabProjectList.length > 0"
      @reloadCompartClick="reloadCompartClick"
      :tabProjectList="tabProjectList"
      :brandId="brandId"
      :filtroStore='filtroStore'
      :filtroProject="filtroProject"
    ></comparison-tab>
  </div>
</template>

<script>
import api from '@/api'
import comparisonTab from '@/views/storeEarlywarning/projectComparison/components/comparisonTab.vue'
import industryComparison from '@/views/storeEarlywarning/projectComparison/components/industryComparison.vue'
import portraitComparison from '@/views/storeEarlywarning/projectComparison/components/portraitComparison.vue'
import peripheryComparison from '@/views/storeEarlywarning/projectComparison/components/peripheryComparison.vue'
export default {
  data () {
    return {
      compContProjectList: [],
      tabProjectList: [],
      comProjectIds: [],
      seatingList: [],
      projectInfoList: []
    }
  },
  components: {
    comparisonTab,
    industryComparison,
    portraitComparison,
    peripheryComparison
  },
  props: {
    brandId: {
      type: Number
    },
    filtroProject: {
      type: Number
    },
    filtroStore: {
      type: String
    }
  },
  mounted () {
    // console.log(this.brandId)
    // console.log(this.filtroProject)
    // console.log(this.filtroStore)
    if (this.$route.name === 'comparisonContent') {
      this.$nextTick(() => {
        document.documentElement.scrollTop = 0
        document.body.scrollTop = 0
      })
      this.init()
      this.getProjectList()
    }
  },
  methods: {
    init () {
      const data = {
        brandId: this.brandId,
        projectId: this.filtroProject,
        storeCode: this.filtroStore
      }
      this.axios.post(api.afterProjectInfo, data)
        .then((res) => {
          this.compContProjectList = res.data.data
          for (const item of this.compContProjectList) {
            if (item.projectId !== this.filtroProject) {
              this.comProjectIds.push(item.projectId)
            }
          }
          this.getSeatingFunc()
        })
    },
    getProjectList () {
      this.reloadCompartClick()
    },
    reloadCompartClick () {
      const data = {
        brandId: this.brandId,
        projectId: this.filtroProject,
        storeCode: this.filtroStore
      }
      this.axios.post(api.getTabComprojectList, data)
        .then((res) => {
          this.tabProjectList = res.data.data
        })
    },
    getSeatingFunc () {
      const data = {
        brandId: this.brandId,
        projectId: this.filtroProject,
        comProjectIds: this.comProjectIds,
        storeCode: this.filtroStore
      }
      this.axios.post(api.seating, data)
        .then((res) => {
          this.seatingList = res.data.data
        })
      const projectIds = JSON.parse(JSON.stringify(this.comProjectIds))
      projectIds.unshift(this.filtroProject)
      const params = {
        projectIds: projectIds
      }
      this.axios.post(api.projectInfoList, params)
        .then((res) => {
          this.projectInfoList = res.data.data
        })
    },
    isCompartClick (item) {
      const data = {
        brandId: this.brandId,
        projectId: this.filtroProject,
        comProjectId: item.projectId
      }
      if (item.isCompart === 0) {
        this.axios.post(api.addCompart, data)
          .then((res) => {
            if (res.data.code === 0) {
              this.init()
            }
          })
      } else {
        this.axios.post(api.deleteCompart, data)
          .then((res) => {
            this.init()
          })
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.comparison-content-wrap
  margin-right 23px
  .comparison-list
    height 300px
    background-color #272930
    border-radius 2px
    display flex
    align-items center
    .item
      flex 1
      text-align center
      .project-img
        img
          width 222px
          height 166px
          object-fit cover
          border-radius 2px
      .project-name
        font-weight bold
        font-size 18px
        color #fff
        margin-top 24px
.drop-wrap, .project-info
  margin-top 14px
  background-color #272930
  .item-wrap
    display flex
    .item
      flex 1
      text-align center
      margin-top 30px
      .info
        .value
          display inline-block
          // font-weight bold
          font-size 24px
          color #fff
          position relative
          line-height 28px
          .fixed
            position absolute
            right -70px
            top -15px
            width 64px
            height 31px
            box-shadow 0px 4px 16px rgba(0, 0, 0, 0.6)
            background-color rgba(0, 0, 0, 0.85)
            color #FF754C
            font-size 12px
            line-height 31px
            text-align center
        .label
          font-size 14px
          color #808191
          line-height 20px
          margin-bottom 44px
</style>
